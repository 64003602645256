@import '../../../custom.scss';

.qAWrapper {
    padding: 64px 0px;

    .qATitleContainer {
        text-align: center;

        p {
            color: $primary;
            font-size: 3vw;
            font-weight: bolder;

            @media screen and (max-width:800px) {
                font-size: 5vw;
            }
        }
    }

    .qAContainer {
        background-color: $light-gray;
        padding: 24px 0px;
        width: 100%;
        overflow: hidden;

        .qaCardContainer {
            padding: 16px 24px;
            border: 2px solid $white;

            @media screen and (max-width:991.5px) {
                height: auto !important;
            }

            .qaCardQuestionContainer {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 16px;

                .qaCardQuestionBadge {
                    background-color: $secondary !important;
                    color: $gray;
                    font-size: 20px;
                    border-radius: 0px;
                }

                .qaCardQuestion {
                    margin-left: 16px;
                    margin-bottom: 0px;
                    text-transform: capitalize !important;
                    color: $primary;
                    font-size: 1.5vw;

                    @media screen and (max-width:991.5px) {
                        font-size: 2vw;
                    }

                    @media screen and (max-width:550px) {
                        font-size: 2.5vw;
                    }

                    @media screen and (max-width:380px) {
                        font-size: 3.5vw;
                    }
                }
            }

            .qaCardAnswerContainer {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                .qaCardAnswerBadge {
                    background-color: $gray !important;
                    color: $white;
                    font-size: 20px;
                    border-radius: 0px;
                }

                .qaCardAnswer {
                    margin-left: 16px;
                    font-size: 1vw;

                    @media screen and (max-width:991.5px) {
                        font-size: 1.5vw;
                    }

                    @media screen and (max-width:550px) {
                        font-size: 2vw;
                    }

                    @media screen and (max-width:380px) {
                        font-size: 3vw;
                    }
                }
            }
        }
    }
}