@font-face {
    font-family: Montserrat;
    src: url(./assets/fonts/Montserrat-VariableFont_wght.ttf);
}

* {
    font-family: Montserrat !important;
}

body::-webkit-scrollbar {
    display: none !important;
}