@import '../../../custom.scss';

.serviceWrapper {

    .serviceTitle {
        padding: 64px 0px 32px 0px;
        text-align: center;

        p {
            font-size: 2.5vw;
            font-weight: bold;
            color: $primary;

            @media screen and (max-width: 600px) {
                font-size: 4vw;
            }
        }
    }
}


.serviceContainer {
    height: 100%;
    width: 100%;
    padding: 64px 0;
    display: grid;
    place-content: center;

    .serviceCards {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .serviceCard {
        margin: 8px;
        max-width: 300px;
        color: #ffffff;
        padding: 1.5rem;
        background-color: $primary;
        pointer-events: auto;
        transform: scale(1);
        opacity: 1;
        transition: all 150ms ease-in-out;
        display: flex;
        flex-direction: column;

        .serviceCardTitle {
            position: relative;
            color: $secondary;

            &::before {
                content: "";
                height: 1rem;
                width: 1rem;
                border-radius: 50%;
                box-shadow: inset 0 0 3px $secondary;
                background-color: transparent;

                position: absolute;
                right: 0;
            }
        }

        .serviceDescription {
            font-size: 0.9rem;
        }

        &:nth-child(even) {
            transform: translateY(8px);
        }

        &:nth-child(n) {
            transform: rotate(-5deg);
        }
    }
}

.serviceCards:hover>.serviceCard:hover {
    transform: scale(1.15);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.serviceCards:hover>.serviceCard:not(:hover) {
    background-color: $gray;
}