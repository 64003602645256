@import '../../custom.scss';

.signInWrapper {
    .signinLabel {
        text-align: center;
    }

    .inputLabel {
        font-weight: bold;
        font-size: 14px;
    }

    .input {
        border-radius: 0px;
    }

    .signInMessage {
        color: green;
        text-align: center;
        padding: 4px 0px;
    }

    .signInSubmitButton {
        width: 100% !important;
        background-color: $gray !important;
        border-radius: 0px !important;
        border: none !important;
        padding: 16px !important;
        color: $white !important;
    }
}