@import '../../custom.scss';

.myTicketWrapper {

    .ticketReceiver {
        text-align: center;
        color: $primary;
    }

    .ticketIdTitle {
        text-align: center;
    }

    .inputLabel {
        font-weight: bold;
        font-size: 14px;
    }



    .ticketRandomNumberContainer {
        display: flex;
        align-items: center;
        justify-content: center;

        .ticketRandomNumber {
            width: auto;
            background-color: $secondary;
            padding: 8px;
            color: $gray;
            font-weight: bold;
            border-radius: 0px;
            font-size: 18px;
        }
    }

    .input {
        border-radius: 0px;
    }

    .phoneNumberInput {
        border: 1px solid #c4c4c4 !important;
        padding: 5px 8px;
        border-radius: 0px;

        .PhoneInputInput {
            border: none !important;
        }
    }

    .dateLossInput {
        width: 100%;
        border: 1px solid #c4c4c4 !important;
        padding: 6px 8px;
        border-radius: 0px;
    }

    .sendTicketSubmitButton {
        width: 100% !important;
        background-color: $primary !important;
        border: none !important;
        padding: 12px !important;
        border-radius: 0px !important;
        color: $white !important;
    }
}