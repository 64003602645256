@import '../../../custom.scss';

.navbar {
    background-color: $primary;
}

.navbarLinks {
    text-decoration: none;
    text-transform: uppercase;

    p {
        font-size: 14px;
        font-weight: bold;
        padding: 8px 16px;
        color: $white;
        margin: 0 !important;
    }
}

.navbarLink {
    text-decoration: none;
    color: $black !important;
}

.active {
    text-decoration: none !important;
    text-transform: uppercase;

    p {
        font-size: 14px;
        font-weight: bold;
        padding: 8px 16px;
        color: $secondary;
        margin: 0 !important;
    }

}


.offcanvasNavbarContainer {
    background-color: $primary;
}

.offCanvasTitle {
    color: $white;
}

.navbar-light .navbar-toggler {
    border: none !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url(../../../assets/images/menu.png) !important;
}

.offcanvas-header .btn-close {
    background-image: url(../../../assets/images/close.png) !important;
    background-size: 100% 100%;
}

.dropdown-toggle {
    margin-top: 8px;
    background-color: $primary !important;
    border: none !important;
    // border-radius: 100px;
}

.dropdown-toggle::after {
    display: none !important;
}

.navbarSingUpButton {
    margin-top: 8px;
    border-radius: 0px;
    background-color: $secondary;
    color: $primary;
    border: none;
    margin-left: 8px;

    @media screen and (max-width:991.5px) {
        margin-left: 0px !important;
    }
}

.navbarSignInButton {
    margin-top: 8px;
    border-radius: 0px;
    background-color: $secondary;
    border: none;
    color: $primary;

}

.navbarSingUpButton:hover,
.navbarSingUpButton:focus,
.navbarSignInButton:hover,
.navbarSignInButton:focus {
    background-color: $secondary !important;
}