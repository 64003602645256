@import '../../../custom.scss';


.floatingWrapper {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .floatingSectionTitle {
        text-align: center;
        padding: 24px 0px;
        color: $black;
    }

    .floatingContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .floatingDataContainer {
            width: 400px;
            height: 320px;
            padding: 24px;
            margin: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 0px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            .floatingDataIcon {
                padding: 8px 0px;
            }

            .floatingTitleContainer {
                font-size: 2vw;
                padding: 8px 0px;
                font-weight: bold;
                text-align: center;

                @media screen and (max-width:575.5px) {
                    font-size: 5vw;
                }
            }

            .floatingDescriptionContainer {
                font-size: 1vw;
                text-align: center;

                @media screen and (max-width:1000px) {
                    font-size: 1.5vw;
                }

                @media screen and (max-width:575.5px) {
                    font-size: 3vw;
                }
            }
        }
    }


}

.lightTheme {
    background-color: $dark-blue;
    color: $white;
}

.DarkTheme {
    background-color: $gray;
    color: $white;
}