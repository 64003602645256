@import '../../custom.scss';

.ticketProgressWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .ticketProgressContainer {
        height: 400px;
        padding: 0px 8px;
        width: 100%;
        overflow-y: auto;
        overflow-x: auto;

        .ticketProgressTitle {
            text-align: center;
        }


        .ticketProgressCardContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            flex-direction: row;

            .ticketProgressCard {
                display: flex;
                margin: 8px;
                padding: 24px;
                flex-direction: column;
                height: auto;
                font-weight: bold;
            }

            .ticketProgressCardPending {
                border: 1px solid $primary;
            }

            .ticketProgressCardSuccess {
                border: 1px solid $primary;
            }
        }



    }
}

.ticketProgressContainer::-webkit-scrollbar {
    width: 10px;
}

.ticketProgressContainer::-webkit-scrollbar-track {
    background-color: $white;
}

.ticketProgressContainer::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 0px;
}

.ticketProgressContainer::-webkit-scrollbar-thumb:hover {
    background: $primary;
}