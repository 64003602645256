@import '../../../custom.scss';

.recoveryServiceWrapper {
    height: 100vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $gray;

    @media screen and (max-width:767.5px) {
        height: auto;
        padding: 64px 0px;
    }

    .recoveryServiceTitlesContainer {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width:767.5px) {
            height: auto;
        }

        .recoveryServiceTitleOne {
            font-weight: bold;
            font-size: 2vw;
            color: $white;
            z-index: 9999;

            @media screen and (max-width: 767.5px) {
                font-size: 3vw;
                z-index: 0;
            }
        }

        .recoveryServiceTitleTwo {
            font-weight: bolder;
            font-size: 2.5vw;
            color: $secondary;
            z-index: 9999 !important;

            @media screen and (max-width: 767.5px) {
                font-size: 3vw;
                z-index: 0;
            }
        }

        .recoveryServiceDescription {
            font-size: 1.2vw;
            z-index: 9999 !important;

            @media screen and (max-width: 767.5px) {
                z-index: 0;
                font-size: 2vw;

            }
        }

        .recoverySubTitle {
            padding: 10px 0px;
            color: $primary;
            font-size: 1.5vw;
            font-weight: bold;
            text-align: left;
            z-index: 9999 !important;

            @media screen and (max-width: 767.5px) {
                font-size: 3.5vw;
                text-align: center;
                z-index: 0;
            }
        }

        .recoveryServiceButton {
            margin: 16px 0px;
            padding: 12px 32px;
            background-color: $secondary !important;
            color: $primary;
            border-radius: 100px !important;
            border: none;
            font-weight: bold;
            font-size: 18px;
        }
    }



    .introductionImageContainer {
        width: 100%;
        height: 100vh !important;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width:767.5px) {
            display: none;
        }

        @media screen and (min-width:1400px) {
            width: 122.8%;
        }

        img {
            width: 130%;
        }
    }
}