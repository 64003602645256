@import '../../../custom.scss';

.aboutusServiceWrapper {
    padding: 100px 0px;
    text-align: center;
    background-color: $primary;

    .aboutusServiceTitle {
        font-weight: bold;
        font-size: 2vw;
        color: $secondary;

        @media screen and (max-width: 650px) {
            font-size: 3.5vw;
        }

        @media screen and (max-width: 400px) {
            font-size: 5vw;
        }
    }

    .aboutusServiceSubTitle {
        font-size: 1.3vw;
        color: $white;

        @media screen and (max-width:650px) {
            font-size: 2vw;
        }

        @media screen and (max-width:400px) {
            font-size: 3vw;
        }
    }

    .aboutusServicesContainer {
        text-align: left;
        padding: 24px 0px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;


        .aboutusService {
            padding: 16px;
            margin: 16px 8px;
            text-align: center;
            cursor: pointer;
            background-color: $gray;
            border-radius: 0px;



            p {
                margin-bottom: 0;
                font-size: 1.1vw;
                font-weight: bold;
                color: $white !important;

                @media screen and (max-width:900px) {
                    font-size: 2vw;
                }

                @media screen and (max-width:500px) {
                    font-size: 4vw;
                }
            }

        }

    }
}

.aboutusService:hover {
    background-color: $dark-blue !important;
}