@import '../../../custom.scss';

.testimonialWrapper {
    padding: 100px 0px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $gray;

    .testimonialTitleContainer {
        width: 100%;
        text-align: center;

        p {
            color: $primary;
            font-size: 2vw;
            font-weight: bold;

            @media screen and (max-width:700px) {
                font-size: 4vw;
            }
        }
    }

    .testimonialSubTitleContainer {
        text-align: center;

        p {
            color: $secondary;
            font-size: 3vw;
            font-weight: bold;

            @media screen and (max-width:700px) {
                font-size: 4.5vw;
            }
        }
    }

    .mySwiper {
        padding: 24px 0px;
        color: $white;

        .ratingNameContainer {
            margin-left: 16px;
        }

        .testimonialBodyContainer {
            padding-top: 24px;
        }

        .swiper {
            width: 100% !important;
            padding-top: 50px !important;
            padding-bottom: 50px !important;
        }

        .swiper-slide {
            width: 300px !important;
            background-color: $primary;
            padding: 24px;
            height: 300px !important;
        }
    }


}

.testimonialsContainer::-webkit-scrollbar {
    display: none !important;
}