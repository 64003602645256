@import '../../../custom.scss';

.introductionWrapper {
    width: 100%;
    background-color: $gray;
    height: 90.3vh;

    .introductionContainer {

        .introductionTitlesContainer {
            width: 100%;
            height: 90.3vh !important;
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;


            .introductionFirstTitle {
                color: $white;
                font-size: 2.3vw;
                z-index: 9999;
                font-weight: bold;

                @media screen and (max-width:991.5px) {
                    font-size: 3vw;
                    z-index: 0;
                }

                @media screen and (max-width:767.5px) {
                    font-size: 4vw;
                    z-index: 0;
                }
            }

            .introductionMainTitle {
                color: $white;
                font-size: 2.5vw;
                font-weight: bolder;
                color: $secondary;
                z-index: 9999;

                @media screen and (max-width:991.5px) {
                    font-size: 3.5vw;
                    z-index: 0;
                }

                @media screen and (max-width:767.5px) {
                    font-size: 5vw;
                    z-index: 0;
                }
            }

            .introductionSecondTitle {
                color: $white;
                font-size: 1.3vw;
                font-weight: 400;
                z-index: 9999;

                @media screen and (max-width:991.5px) {
                    font-size: 2vw;
                    z-index: 0;
                }


                p {
                    opacity: 0.9;
                }

                @media screen and (max-width:767.5px) {
                    font-size: 2.2vw;
                    z-index: 0;
                }

                @media screen and (max-width:450px) {
                    font-size: 3.5vw;
                }
            }
        }

        .introductionImageContainer {
            width: 100%;
            height: 90.3vh !important;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-direction: column;

            @media screen and (max-width:767.5px) {
                display: none;
            }

            @media screen and (min-width:1400px) {
                width: 122.8%;
            }

            img {
                width: 130%;

                @media screen and (max-width:991.5px) {
                    width: 200%;
                }
            }
        }

    }

}