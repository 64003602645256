@import '../../../custom.scss';

.introduction2Wrapper {
    width: 100%;
    height: 90.3vh;
    background-color: $gray;


    @media screen and (max-width:577.5px) {
        height: 110vh;
    }

    @media screen and (max-height:577.5px) {
        height: auto;
    }

    .introduction2ChildrenContainer {
        height: 90.3vh;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 9999 !important;

        @media screen and (max-width:577.5px) {
            height: 110vh;
            z-index: 0 !important;

        }

        @media screen and (max-height:577.5px) {
            height: auto;
        }
    }


    .introduction2ServiceImageContainer {
        width: 100%;
        height: 90.3vh !important;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        flex-direction: column;

        @media screen and (max-width:767.5px) {
            display: none;
        }

        @media screen and (min-width:1400px) {
            width: 122.8%;
        }

        img {
            width: 130%;

            @media screen and (max-width:991.5px) {
                width: 200%;
            }
        }
    }

}