@import '../../../custom.scss';


.serviceHighlightWrapper {
    padding: 64px 0px;
    height: auto;
    background-color: $gray;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    .serviceHighlightMainTitle {
        color: $secondary;
        font-size: 2.3vw;
        font-weight: bold;
        text-align: center;

        @media screen and (max-width:800px) {
            font-size: 4.6vw;
        }
    }

    .serviceHighlightCardsContainer {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;

        .serviceHighlightCard {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            background-color: $primary;
            border-radius: 0px;
            padding: 0px 16px;
            width: 400px;
            height: 320px;
            margin: 16px;

            .serviceHighlightTitle {
                // padding: 16px 0px;
                text-align: left;
                font-size: 1vw;
                font-weight: bold;
                color: $white;

                @media screen and (max-width: 700px) {
                    font-size: 4vw;
                }
            }

            .serviceHighlightDescription {
                padding: 16px 0px;
                text-align: left;
                color: $white;
                opacity: 0.8;

                @media screen and (max-width:350px) {
                    font-size: 14px;
                }
            }
        }
    }
}