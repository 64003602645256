@import '../../custom.scss';

.aboutusServiceTitle {
    font-weight: bold;
    font-size: 2vw;
    color: $primary;
    text-align: center;

    @media screen and (max-width: 650px) {
        font-size: 3.5vw;
    }

    @media screen and (max-width: 500px) {
        font-size: 7vw;
    }
}