@import '../../../custom.scss';

.floatingContainerWrapper {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    width: 100%;
    height: auto;
    padding: 32px 0px;
    border-radius: 0px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width:575.5px) {
        margin-top: 0px;
    }
}